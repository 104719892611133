<template>
    <div class="msp-lista-feedback" >

        <header
    :style="'top:'+stickyTop+'px'"
     class="header-filtri">
<v-text-field
            filled
            solo
            dense
            clearable
            hide-details
            v-model="search.atleta"
            label="Cerca per nome..."
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>                
                 <v-btn plain @click='reload' class='btn-reload'><v-icon>mdi-reload</v-icon></v-btn>
    
                 <v-alert width="100%" type="info" v-if="loading">
                      Caricamento in corso...</v-alert>
        </header>
            <allenamento-feedback-item
                v-for="feed in filteredItems" :key="feed.id"
                :atleta="feed.atleta"
                :allenamento = "feed"
                @open-commenti = "openCommenti"
                @open-attivita = "openAttivita"
            />
            <div>
                <div class="info-dati">Dati dal {{formatDate(from)}}</div>
                <v-btn block v-if="!loading" class="btn btn-full" @click="loadMore">
                    Carica altri {{inc}} giorni</v-btn>
            </div>

    </div>
</template>

<script>
import AllenamentoFeedbackItem from './AllenamentoFeedbackItem.vue';
import allenamentoCalcoli from '@src/js/vue/mixins/allenamentoCalcoli.js'
import _calendario from '@src/js/vue/mixins/calendario.js'
import env from '@mixins/environment.js'
import {  mapState } from 'vuex'

const component = {
    components: {
        AllenamentoFeedbackItem,
    },
    props: [
        'sticky-top',
        'atleti',
    ],
    mixins: [
        allenamentoCalcoli,
    env,
    ],
    data: function () {
        return {
            loading: false,
            options: {
                searchIn: "atleta",
                sortBy: "cognome"
            },
            day:null,
            search: {
                atleta: '',
            },

        }
    },
    mounted () {
        this.init();
    },
    computed: {
    ...mapState(
      {
          newsFeeds: (state)=>state.newsfeed.newsFeeds,
          from: (state)=>state.newsfeed.from,
          to: (state)=>state.newsfeed.to,
          inc: (state)=>state.newsfeed.inc,
      }
    ),

        itemsAtleta: function () {
            if (!(this.newsFeeds && this.newsFeeds.length)) {
                return [];
            }
            if (!this.atleti) {
                return this.newsFeeds;
            }
            let items = this.newsFeeds.map((el)=>{
                const atleta =  this.findAtleta(el.calendario_utente_id);
                return {
                    ...el,
                    atleta:  atleta
                }
            });
            return items;
        },
        filteredItems: function () {
            let items = this.MSP().filter(this.itemsAtleta, this.search.atleta, ['atleta.nome', 'atleta.cognome']);
            return items;
        },
    },
    methods: {

        /** Ordina gli allenamenti per data descrescente */
        sort: function (a, b) {
            return new Date(b.data) - new Date(a.data);
        },

        findAtleta: function (id) {
            if(!(this.atleti && this.atleti.length)) return false;
            const atleta = this.atleti.find(el=>el.id == id);
            return atleta;
        },

        init: function () {
            let today = _calendario.date2ymd();
            this.setDay(today);
            this.setRange(this.day);
            this.loadListaAttivita();
        },
        setRange: function (to) {
            const from = _calendario.addDays(to, -1*this.inc + 1);
            const limits = {
                from: from,
                to: to,
            };
            this.$store.dispatch("newsfeed/setLimits",limits);
        },

        emptyListaAttivita: function () {
            this.$store.dispatch("newsfeed/resetNewsFeeds");
        },

        reload: function () {
            this.emptyListaAttivita();
            this.setDay(this.to);
            this.loadListaAttivita();
        },
        loadMore: function () {
            let day = _calendario.addDays(this.from, -1);
            this.setRange(day);
            this.loadListaAttivita();
        },
        setDay: function (day) {
            if (day) {
                this.day = day;
            } else {
                this.day = _calendario.date2ymd();
            }
        },
        loadListaAttivita: async function () {
            this.loading = true;
            await this.$store.dispatch("newsfeed/loadNewsFeeds");
            this.loading = false;
        },

        openAttivita: function (attivita_id) {
            this.$emit('open-attivita', attivita_id);
        },
        openCalendario: function (feed) {
            this.$emit('open-calendario', feed);
        },
        openCommenti: function (feed) {
            this.$emit('open-commenti', feed);
        },
        openPerformance: function (feed) {
            this.$emit('open-condizione-atletica', feed);
        },
    },

}
    export default component;
</script>

<style lang="scss">
.msp-lista-feedback {
    position: relative;
    .header-filtri {
        position: sticky;
        background: #fff;
        padding: 3px;
        z-index: var(--z-top);
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        .alert {
            grid-column: 1 / -1;
        }
        .btn-reload {
            align-self: stretch;
            height: 100%;
            grid-column: -1;
        }
    }
    .info-dati {
        padding: 3px 5px; 
        text-align: center;
        color: var(--col-grigio-medio);
    }
}
</style>
