<template>
    <v-list-item-avatar color="#ccc">
        <v-img v-if="utente.url_fotoprofilo" :src="imgAvatar(utente)">
        </v-img> 
        <span v-else class="text-h6" v-text="iniziali(utente)"></span>
    </v-list-item-avatar>   

</template>

<script>
import utilityAtleta from "@mixins/utilityAtleta.js"
    const component = {
        props: [
            'utente',
        ],
        mixins: [
            utilityAtleta,
        ],
    }
    export default component;
</script>

