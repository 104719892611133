import env from "./environment.js"

export default {
    mixins: [
    env,
    ],
    methods: {
        imgAvatar: function (atleta) {
            if (!atleta.url_fotoprofilo) return "";
            let base = this.api_base_url+"/public/avatar/";
            let src = base + atleta.url_fotoprofilo;
            return src;
        },
        nomeAtleta: function (atleta) {
            const cognome = this.nome(atleta);
            const nome = this.cognome(atleta);
            return cognome + ' ' + nome;
        },
        cognome: function (atleta) {
            const cognome = (atleta.atleta_cognome) ? atleta.atleta_cognome : atleta.cognome;
            return cognome;
        },
        nome: function (atleta) {
            const nome = (atleta.atleta_nome) ? atleta.atleta_nome : atleta.nome;
            return nome;
        },
        iniziali: function (atleta) {
            let iNome;
            let iCognome;
            let nome = this.nome(atleta);
            let cognome = this.cognome(atleta);
            if (cognome) {
                iCognome = cognome.substr(0,1).toUpperCase();
            } else {
                iCognome = "";
            }
            if (nome) {
                iNome = nome.substr(0,1).toUpperCase();
            } else {
                iNome = "";
            }
            return iCognome+iNome;
        },
    }
};
