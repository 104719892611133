<template>
    <div class="msp-menu-options" :class="classMenu" >
        <i @click.stop="openMenu()" class="msp-menu-options__toggle msp-icon-menu" alt="" tabindex="1"></i>
        <div ref='container' class="msp-menu-options__container">
            <div ref='float' class='msp-menu-options__float'>
                <ul :style="{top: top, left: left, right: 'auto' }" class='msp-menu-options__items' ref='items'>
                    <slot name="menu-items" v-bind:close="closeMenu"></slot>
                </ul>
                <div ref='backdrop' @mouseover.prevent.stop="doNothing" @click.stop.prevent="closeMenu()"  class="msp-menu-options__backdrop"></div>
            </div>
        </div>
    </div>
</template>

<script>
    const component = {
        created: function () {
            document.addEventListener('keydown', this.tryToClose);
        },
        beforeDestroy: function () {
            document.removeEventListener('keydown', this.tryToClose);
        },
        props: [
        ],
        computed: {
            classMenu: function () {
                let a_classes = [];
                if (this.hasMenu) {
                    a_classes.push('has-menu');
                }

                return a_classes.join(" ");
            },
        },
        data() {
            return {
                top: "0px",
                left: "0px",
                hasMenu: false,
            }
        },
        methods: {
            tryToClose: function (ev) {
                if (ev.key==='Escape') {
                    this.closeMenu();
                }
            },
            moveOnTop: function () {
                let parentNode = document.querySelector('.msp-lo-container');
                const float = this.$el.querySelector('.msp-menu-options__float');
                const rect = this.$el.getBoundingClientRect();
                const {top,left} = rect;
                this.top = top+"px";
                this.left = left+"px";
                parentNode.append(float);
            },
            resetPosition: function () {
                let parentNode = this.$refs.container;
                if (!parentNode) return;
                parentNode.append(this.$refs.float);
            },
            openMenu: function () {
                //quando apri il menu, sposta tutto in fondo al codice, in modo che sia sopra a tutto
                this.moveOnTop();
                this.hasMenu = true;
            },
            doNothing: function () {
                return false;
            },
            closeMenu: function () {
                this.resetPosition();
                this.hasMenu = false;
            },
        }
    }
    export default component;
</script>

<style lang="scss">
    .msp-menu-options {
        font-size: 12px;
        position: static;
        --display: none;
        --z-index: var(--z-menu);
        &.menu-hamburger {
            position: relative;
        }
        &__toggle {
            right: 0;
            color: var(--col-grigio-medio);
            cursor: pointer;
            text-align: right;
        }

        &__container {
        }

        &__items {
            --z-index: calc(3 + var(--z-menu));
            display: var(--display);
            position: fixed;
            z-index: var(--z-index);
            border-radius: 2px;
            box-shadow: 0 0 5px rgba(0,0,0,0.5);
            background: var(--col-grigio-chiaro);
            border: 1px solid var(--col-grigio);
            border-radius: var(--ui-radius);
            cursor: pointer;
            transform: translate3d(-100%,0,0);

            padding: 0;
            list-style: none;
            margin: 0;

            li{
                --padding: 12px;
                padding: var(--padding);
                border-bottom: 1px solid #fff;

                &:last-child{
                    border: none;
                }

                &:hover,
                &:active{
                    background: var(--col-grigio);
                    color: #fff;
                }
                &.menu-elimina {

                    padding: 0;
                    & .msp-confirm__button {
                        padding: var(--padding);
                    } 


                    .btn-icon {
                        width: auto;
                    }

                    &:hover,
                    &:active{
                        background: var(--col-danger-chiaro);
                    }
                }
            }
        }

        &__backdrop {
            --z-index: calc(2 + var(--z-menu));
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: var(--z-index);
            background: #fff3;
            display: var(--display);
        }
        &.has-menu {
            .msp-menu-options__container {
                --display: block;
            }
        }
    }
</style>
