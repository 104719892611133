<template>
    <div class="feedback">
    <msp-app-bar title="News feed"></msp-app-bar>


    <msp-lista-feedback 
        :atleti="atleti"
        @open-commenti="openCommenti"
        @open-attivita="openDettaglioAttivita"
        :sticky-top="headerHeight"
    ></msp-lista-feedback>
            
        
<msp-app-footer :hidden = "true" ></msp-app-footer>
<v-dialog
v-model="showModal"
fullscreen
hide-overlay
transition="slide-x-transition"
:return-value="'detail'"
>
<router-view></router-view>
</v-dialog>
    </div>

    </template>

    <script type="module">
    import mspAppBar from "@components/MspAppBar.vue";
    import mspAppFooter from "@components/MspAppFooter.vue";
    import MspListaFeedback from "@components/MspListaFeedback.vue";
    import { mapGetters, mapState } from 'vuex'
import messaggi from '@mixins/messaggi.js';
    const component = {
      mixins: [
      ],
      components: {
        mspAppBar,
        mspAppFooter,
        MspListaFeedback

      },
      data () {
        return {
            showModal: false,
            appBarHeight: 0,
    }
  },
  mounted () {
  },
  watch: {
    dialog: function (val) {
      this.showModal = val;
      },
    },
  computed: {
    ...mapState(
      {
        dialog: (state)=>state.calendario.dialog,
      }
    ),
    ...mapGetters('atleti', [
      'atleti',
    ]),
    ...mapGetters('app', [
      'headerHeight',
    ]),
  },
  methods: {
      updateAppBarHeight: function (height) {
          this.appBarHeight = height;
      },
      openCommenti: async function ({calendario_id}) {
          const newChat = await messaggi.addChat('calendario', calendario_id);
          if (!newChat) return;
          const chatId = newChat.chat_id;
          this.$store.dispatch("messaggio/setDialog",true);
          this.$router.push("/chats/"+chatId);
      },
      openDettaglioAttivita: function (id) {
          this.$store.dispatch("calendario/setDialog",true);
          this.$router.push("/calendario/attivita/view/"+id);
      }
  },
}
export default component
</script>


<style lang="scss">
</style>
