<template>
    <v-bottom-navigation app class="msp-app-footer" v-if = "isCoach && !hidden" >
        <span v-if="atleta">
            <v-list-item
                @click = "showAtleti"
            >
                <msp-avatar :utente='atleta'></msp-avatar>
                <v-list-item-content><v-list-item-title v-text="nomeAtleta(atleta)"></v-list-item-title></v-list-item-content>
            </v-list-item>
        </span>
        <span v-else>
            <v-list-item
                @click = "showAtleti"
            >
                <v-list-item-avatar color="#ccc">
                    <span class="text-h6"><v-icon>mdi-account</v-icon></span>
                </v-list-item-avatar>
                <v-list-item-content><v-list-item-title >Seleziona atleta</v-list-item-title></v-list-item-content>
            </v-list-item>
        </span>

    </v-bottom-navigation>
</template>
<script>
import { mapGetters } from 'vuex'
import MspAvatar from "./MspAvatar.vue"
import utilityAtleta from "@mixins/utilityAtleta.js"
import _storage from "@mixins/storage.js"

const component = {
    props: {
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        MspAvatar,
    },
    mixins: [
        utilityAtleta,
    ],
    watch: {
    },
    data: function () {
        return {
            isCoach: false,
        }
    },
    computed: {
        ...mapGetters('atleti', [
            'atleti',
            'atleta',
            'atletaId',
        ]),
        ...mapGetters('utente', [
            'utente',
        ]),
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function () {
            this.isCoach = this.utente && !this.utente.is_atleta;
            if (this.isCoach) {
                await this.loadAtleti();
            }

        },

        findAtleta: function (id) {
            if (!this.atleti) return null;
            return this.atleti.find(el=>el.id == id);
        },
        openListAtletiIfNeeded: function () {
            let atletaId;
            if (!this.atletaId) {
                atletaId = _storage.getAtletaId();
            } else {
                atletaId = this.atletaId;
            }
            if (!atletaId) {
                this.showAtleti();
                return;
            } 
            const atleta = this.findAtleta(atletaId);
            if (!atleta) {
                this.showAtleti();
                return;
            }
            this.selectAtleta(atleta);
        },
        selectAtleta: function (atleta) {
            this.$store.dispatch("app/setAtleta", atleta);
        },

        showAtleti: async function () {
            this.$router.push("/atleti");
        },
        loadAtleti: async function () {
            await this.$store.dispatch("atleti/loadAtleti");
            return true;
        },
    },

}

export default component;
</script>
<style lang="scss">
.msp-app-footer {
    &.v-bottom-navigation {
        justify-content: stretch;
        background-color: var(--col-msp-chiaro) !important;
        &>span {
            flex: 1;
        }
    }
}
</style>
