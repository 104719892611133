<template>
<v-card outlined class="mb-2 evento-calendario allenamento-feedback-item"
        :class="classAllenamento"
    >
        <div class="allenamento-feedback-item__content">
            <msp-avatar class="cell-icon" v-if="utente" :utente="utente"></msp-avatar>
            <span class="cell-text header">
                <span v-if="atleta" class="atleta-nome">{{nomeAtleta}}</span>
                <div class="msp-ui2-spacer"></div>
                <span class="data">{{dataAllenamento}}</span>
            </span>
            <p class="cell-text allenamento-titolo">{{allenamento.titolo}}
                <i v-if="!isRiposo" class="sport-icona" :class="'msp-icon-sport-'+allenamento.sport_id" alt="" />
</p>
    
            <template v-if='ultimoCommento'>
                <i class="cell-icon commento__icona" :class="iconNote"></i>
                <div class="cell-text commento__testo" v-html="ultimoCommento"></div>
            </template>
        </div>

        <div class="ev-all__footer" v-if="allenamentoHasFooter(allenamento)">
            <div class="slot slot-valutazione" v-if="allenamentoHasSlotValutazione(allenamento)">
                <span :title="getValutazione(allenamento.feedback_valutazione).titolo" v-if="allenamento.feedback_valutazione" :style="'--color: '+getValutazione(allenamento.feedback_valutazione).colore" class="icon-feedback valutazione"><i :class="getValutazione(allenamento.feedback_valutazione).icona"></i></span>
            </div>
            <div class="slot slot-note" v-if="allenamentoHasSlotNote(allenamento)">
<v-btn plain  :class="classNote" small @click.prevent.stop="toggleCommenti(allenamento)">
      <v-icon class="icon-feedback note">{{iconaCommenti}}</v-icon><span class='count' v-if="countCommenti">{{countCommenti}}</span>
  </v-btn>

            </div>
            <div class="slot slot-percentuale" v-if="allenamentoHasSlotPercentuale(allenamento)">

<v-btn class="slot__percentuale" v-if="allenamento.attivita_id" plain small @click.prevent.stop="openAttivita(allenamento.attivita_id)"><i class="msp-icon-graph"></i><span  class="icon-feedback percentuale" > {{allenamento.attivita.percRaggiungimento}} % </span></v-btn>
  
                <span v-else class="percentuale off">-%</span>
            </div>
            <div class="slot slot-rpe" v-if="allenamentoHasSlotRpe(allenamento)">
                <span v-if="allenamento.feedback_rpe" class="rpe on">
                    <i class="msp-icon msp-icon-body"></i>
                    {{allenamento.feedback_rpe}}
                    </span> <span v-else class="rpe off">
                    <i class="msp-icon msp-icon-body"></i>
                </span>
            </div>
        </div>


    </v-card>
</template>

<script>
import MSP from "@mixins/msp.js";
import MspAvatar from './MspAvatar.vue';
import allenamento_calcoli from '@src/js/vue/mixins/allenamentoCalcoli.js';
import allenamentoFooter from '@src/js/vue/mixins/allenamentoFooter.js';
import MspMenuOptions from '@src/js/vue/include/MspMenuOptions.vue';
import messaggi from '@mixins/messaggi.js';
import VALUTAZIONI from '@cached/valutazione_allenamento.json';
const component = {
    components: {
        MspAvatar,
        MspMenuOptions,
    },
    props: [
        'allenamento',
        'atleta',
    ],
    computed: {
        iconaCommenti: function () {
            if (this.countNuoviCommenti) {
                return "mdi-chat";
            }
            return "mdi-chat-outline";
        },
        classNote: function () {
            if (this.countNuoviCommenti) {
                return "nuovi";
            }

            return "";
        },
        ultimoCommento: function () {
            if (!this.allenamento) return false;
            if (!this.allenamento.chat_ultimo) return false;
            let excerpt = messaggi.makeExcerpt(this.allenamento.chat_ultimo.testo);
            return excerpt;
        },
        utente: function () {
            if (!this.atleta) return null;
            return {
                ...this.atleta,
                nome: this.atleta.nome,
                cognome: this.atleta.cognome,
            }
        },
        dataAllenamento: function () {
            if (!this.allenamento) return "";
            return MSP.formatDate(this.allenamento.data);
        },
        nomeAtleta: function () {
            if (!this.atleta) return "";
            return `${this.atleta.nome} ${this.atleta.cognome}`;
        },
        countNuoviCommenti: function () {
            if (!this.allenamento.commenti) return 0;
            return this.allenamento.commenti.nuovi.length;
        },
        countCommenti: function () {
            if (this.allenamento.chat_count_totale) return this.allenamento.chat_count_totale;
            if (!this.allenamento.commenti) return 0;
            return this.allenamento.commenti.messaggi.length;
        },
        isRiposo: function ()
        {
            if (!this.allenamento)
            {
                return false;
            }
            return this.allenamento.sport_id === MSP.ID_RIPOSO
        },
        classAlert: function ()
        {
            if (!this.allenamento)
            {
                return "";
            }
            if (+this.allenamento.alert)
            {
                return "active";
            }
            return "";
        },
        countCommentiNuovi: function () {
            return MSP.deepProp(this.allenamento,'commenti.nuovi.length') || 0;
        },
        iconNote: function () {
            if (!this.allenamento) {
                return "msp-icon-note-vuoto";
            }
            if (this.countCommentiNuovi) {
                return "msp-icon-note";
            }
            return "msp-icon-note-vuoto";
        },
        classAllenamento: function () {
            if (!this.allenamento) {
                return "";
            }
            let a_ca = [];
            a_ca.push("allenamento-sport-"+this.allenamento.sport_id);

            if (this.selected) {
                a_ca.push('selected');
            }

            if (this.ghost) {
                a_ca.push('ghost');
            }
            if (this.over) {
                a_ca.push('over');
            }
            if (!this.allenamento.risultato_id) {
                a_ca.push('hasNotRisultato');
            }
            if (this.hasMenu) {
                a_ca.push('has-menu');
            }

            return a_ca.join(" ");
        }
    },
    mixins: [
        allenamentoFooter,
        allenamento_calcoli,
    ],

    data: function () {
        return {
            hasMenu: false,
            over: false,
            showNote: false,
            ghost: false,
            feedback: false,
            coloriPercentuale: this.getCommentoPercentuali(),
            type: 'allenamento',
        }
    },
    watch: {

    },
    mounted: function() {
        //do something after mounting vue instance
        this.mounted();

    },
    methods: {
        openAttivita: function (id) {
            this.$emit('open-attivita', id);
        },
        renderRisultato: function (type, um) {
            const allowed = [
                'durata',
                'distanza',
            ];
            if (allowed.indexOf(type) === -1) return "";
            let result;
            let sec = this.allenamento.totalone.sec;
            let mt = this.allenamento.totalone.mt;
            if (type === 'distanza') {
                result = this.formatoRisultato(mt, um);
                return result;
            }
            if (type === 'durata') {
                result = this.formatoRisultato(sec, um);
                return result;
            }
        },
        canSelectItem: function (item) {
            return this.isOwner(item) || this.canEdit(item);
        },

        isOwner: function (item) {
            return item && item.owner;
        },

        canEdit: function (item) {
            return MSP.utente.canEdit(item);
        },

        getValutazione: function (id) {
            let valutazioni = VALUTAZIONI;
            let valutazione = valutazioni.find(el=>el.id==id);
            let defaultValutazione = {
                colore: "#ccc",
                titolo: "",
            };
            if (!valutazione) {
                return defaultValutazione;
            } 
            return valutazione;
        },
        markAsRead: function (allenamento)
        {
            this.$emit("mark-as-read", allenamento);
            this.allenamento.feedback_status = "letto";
            this.toggleCommenti(allenamento);
        },

        getColor: function (percentuale) {
            let elCol = this.coloriPercentuale.find(el=>(el.min<percentuale && el.max >= percentuale));
            return elCol ? elCol.col : "#ccc";
        },

        toggleAlert: function (allenamento) {
            let alert = (allenamento.alert) ? 0 : 1;

            this.$emit("update-alert",allenamento.calendario_id,alert);
        },
        toggleCommenti: function (allenamento) {
            this.showNote = !this.showNote;
            this.$emit("open-commenti", allenamento);
        },


        clipboardSelect: function (type, data)
        {
            if (!this.draggable)
            {
                this.$emit('clipboard-copy', type, data)

            }
        },


        mounted: function() {
            this.$emit('mounted', this.allenamento);
        },

    }
}
export default component;
</script>
<style lang="scss">
.allenamento-feedback-item {
    .allenamento-titolo {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 5px;
    }
   .v-btn.nuovi .v-icon {
      color: var(--col-msp-scuro);
    }
    .v-avatar {
        margin: 0 !important;
    }
    .data {
        font-size: 0.8em;
    }
    --background-evento: white;
    border-bottom: 3px solid var(--col-grigio-chiaro);
    margin: 0;
    padding: 10px 5px;
    width: 100%;

    &__content {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 5px;
    }
    .cell-icon {
        grid-column: 1;
    }
    .cell-text {
        grid-column: 2;
    }

    .header {
        display: flex;
        justify-content: stretch;
        align-items: center;
    }

    .atleta-nome {
        font-weight: bold;
    }

    .sport-icona {
        font-size: 30px;
    }

    .commento__icona {
        text-align: right;
        font-size: 20px;
        color: var(--col-grigio);
    }

    .commento__testo {
        font-style: italic;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ev-all__footer {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "valutazione note percentuale rpe";
    width: 100%;
    height: 30px;
    grid-column: 1 / -1;
    border-top: 1px solid var(--col-grigio-chiaro);
    .slot {
        display: flex;
        justify-content: center;
        align-items: center;
        .off {
            opacity: 0.3;
        }
        .on {
            opacity: 1;
        }
        position: relative;
        i {
            position: relative;
        }
        &-note {
            grid-area: note;
            justify-content: stretch;
            align-items: stretch;
        }
        &-rpe {
            grid-area: rpe;
        }
        &-valutazione {
            grid-area: valutazione;
        }
        &-percentuale {
            grid-area: percentuale;
            justify-content: stretch;
            align-items: stretch;
        }
    }

    .btn-alert {
        background: none;
        opacity: 0.5;
        color: var(--col-grigio);
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
        i.msp-icon-warning {
            margin: 0 auto;
        }
        &.active {
            background: var(--col-msp);
            color: #fff;
            opacity: 1;
        }
    }
    .slot .btn {
        --contrast: var(--col-grigio-chiaro);
        border-color: 1px solid var(--color);
        &.btn-percentuale {
            --contrast: #fff;
            font-size: 0.9em;
            box-shadow: none;
        }
    }
    .btn-rpe {
        white-space: nowrap;
        --color: var(--col-grigio);
    }


    .percentuale {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
    .btn-attivita {
        --color: var(--col-grigio);
        border-color: 1px solid var(--color);
        color: var(--color);

        grid-column: 2/-1;
    }
    .btn-note-container {
        .btn {
            --color: var(--color-note);
            --contrast: var(--contrast-note);
        }

        --color-note: var(--col-grigio-chiarissimo);
        position: relative;
        opacity: 1;
        --contrast-note: var(--col-grigio);
        display: flex;
        flex-grow: 1;
        flex-basis: 20px;
        justify-content: center;
        align-items: center;
        &.active {
            --contrast-note: var(--col-msp);
            opacity: 1;
        }
        &.letto {
            --color-note: var(--col-msp);
            --contrast-note: #fff;
        }
    }


    .tooltip-note {
        pointer-events: none;
        position: absolute;
        padding: 1em;
        background: var(--col-grigio-scuro);
        color: #fff;
        text-align: left;
        border-radius: 10px;
        z-index: var(--z-tooltip);
        pointer-events: none;
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 300ms ease-in-out;
        &.nowrap {
            white-space: nowrap;
        }
        &.active {
            transition: opacity 300ms ease-in-out;
            opacity: 1;
            width: auto;
            height: auto;
            min-width: 300px;
            pointer-events: all;
        }

        .btn-close {
            align-self: flex-end;
            cursor: pointer;
        }
        .feedback-atleta {
            margin: 1em 0;
        }
    }


    .tooltip-note.note {
        width: auto !important;
        max-width: 400px;
        bottom: 100%;
        left: 0;
        transform: translate(0%, 0%);
        display: none;
        padding-bottom: 30px !important;
        white-space: pre-wrap;
        &.active {
            display: flex;
            flex-direction: column;
        }

    }


}


    .icon-feedback {
        &.valutazione i {
            font-size: 1em;
            width: 1.2em;
            height: 1.2em;
            background: none;
            border: 1.5px solid var(--col-grigio);
            display: grid;
            align-content: center;
            align-items: center;
            justify-content: center;
            border-radius: 1em;
            background: var(--col-grigio-chiarissimo);
        }
    }
}
</style>
